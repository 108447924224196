<template lang='pug'>
	.modalStatus(@click.stop :class='[{"isShown": isShown}, status]' v-if='!hideOnPages')
		.modalStatus__info(v-if="!isMobile")
			p.b3.mb-3.modalStatus__text(v-html="$t('modalStatus.'+status)")
			.btn.btn-small.mt-1.mt-md-2.modalStatus__button(@click='goToMore') {{ $t('modalStatus.more') }}

		.modalStatus__info.justify-content-between.modalStatus__info--mobile.modalStatus__info(v-if="isMobile" )
			a.d-xs-block.d-lg-none(href='#' v-b-modal.modalMobileMenu)
				mobile-menu-icon.mt-2.mr-2
			.d-flex.flex-column.modalStatus__text__text-wrap
				p.b3.mb-0.modalStatus__text(v-html="$t('modalStatus.'+status)")


			.btn.btn-yellow.btn-small.ml-2.modalStatus__button(@click="goToMore")
				svg(xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" stroke="currentColor")
					path(d="M11.212 4.25L16.2498 9.5L11.212 14.75" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
					line(x1="15.0497" y1="9.7738" x2="3.74988" y2="9.7738" stroke-width="2" stroke-linecap="round")

</template>

<script>
import { mapGetters } from 'vuex';
import { MobileMenuIcon } from '@/components/Svg'

export default {
    name: 'ModalStatus',
	components: { MobileMenuIcon },
    data: () => ({
        isShown: false,
    }),
    created() {
        this.isShown = true;
    },
    methods:{
        goToMore(){
            this.$router.push({name: "project_settings",query: {tab: 4}})
        },
    },
    computed: {
        ...mapGetters({
            project : 'project/opened',
        }),
        hideOnPages() {
            return this.$route.name === 'profile' || this.$route.name === 'finance'|| this.$route.name === 'project_settings' && this.$route.query?.tab?.toString() === "4";
        },
        status() {
            return this.project?.moderationRequestLast?.status ??'ACTIVE'
        },
    },
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.modalStatus {
    z-index: 9999;
    position: fixed;
    display: none;
    flex-direction: column;
    bottom: 24px;
    left: 100vw;
    width: max-content;
    box-shadow: var(--box-shadow);
    overflow: hidden;
    transform: translateX(calc(-100% - 32px));


    @include respond-below(sm) {
        z-index: 1039;
        bottom: auto;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        transform: translateX(0);
    }

    &.isShown {
        display:flex
    }

	&__text-wrap {
		flex: 1;
	}
    &__text {
        color: var(--red-text-color);
        font-weight: 600;
      	@include respond-below(sm) {
		    margin-left: 20px;
		}
    }
    &__info {
        position: relative;
        padding: 15px 25px;
	    width: 230px;
        border-radius: var(--border-radius-rounded);
        overflow: hidden;
	    @include respond-below(md) {
		    width: 100%;
		    align-items: center;
		    &::before {
			    display: none;
		    }
	    }


        &::before {
            content: '';
            position: absolute;
            width: 90px;
            height: 90px;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &--mobile {
            @include respond-below(sm) {
                display: flex;
                padding: 16px 12px 13px;
                width: 100%;
                height: 68px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;

                &::before {
                    content: none;
                }
            }
        }
        .btn{
            border: none;
        }
    }
    &__button {
        @include respond-below(sm) {
            width: 111px;
            max-height: 33px;
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.05);
        transition: var(--animation-time-short);
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }


    &.ACTIVE {
        .modalStatus__info {
            background-color: var(--brand-primary);
            &::before {
                top: 1px;
                right: 0;
                height: 77px;
                width: 77px;
                background-image: url('/assets/img/modalStatus/watch.png');
            }
            .modalStatus__text {
                color: var(--foreground-color);
            }
            .btn{
                color: var(--primary-color);
                background-color: var(--brand-warning);
                border: none;
				@include respond-below(sm) {
					color: var(--primary-text-color)
				}
            }
	        .wizard__button svg *{
		        stroke: var(--primary-text-color)!important;
	        }
        }
	    &::v-deep .menu-icon-line {
		    border-color: var(--wr-text-color) !important;
	    }
    }
    &.NEW {
        .modalStatus__info {
            background-color: var(--brand-bg-warning);
            &::before {
                top: 0;
                right: 0;
                height: 55px;
                width: 55px;
                background-image: url('/assets/img/modalStatus/refresh.png');
            }
            .modalStatus__text {
                color: var(--brand-orange);
            }
            .btn{
                color: var(--background-back);
                background-color: var(--brand-orange);
            }
	        .wizard__button svg *{
		        stroke: var(--background-back)!important;
	        }
        }
	    &::v-deep .menu-icon-line {
		    border-color: #000 !important;
	    }
    }
    &.REVOKED {
        .modalStatus__info {
            background-color: var(--red-light-bg-color);
            &::before {
                height: 64px;
                width: 70px;
                top: 2px;
                right: -2px;
                background-image: url('/assets/img/modalStatus/roadCone.png');
            }
            .modalStatus__text {
                color: var(--red-text-color);
            }
            .btn{
                color: var(--wr-text-color);
                background-color: var(--red-text-color);
            }
	        .wizard__button svg *{
		        stroke: var(--wr-text-color)!important;
	        }
        }
	    &::v-deep .menu-icon-line {
		    border-color: #000 !important;
	    }
    }
    &.BLOCKED {
        .modalStatus__info {
            background-color: var(--red-light-bg-color);
            &::before {
                height: 64px;
                width: 74px;
                top: 2px;
                right: -2px;
                background-image: url('/assets/img/modalStatus/noEntry.png');
            }
            .modalStatus__text {
                color: var(--red-text-color);
            }
            .btn{
                color: var(--wr-text-color);
                background-color: var(--red-text-color);
            }
	        .wizard__button svg *{
		        stroke: white!important;
	        }
        }
	    &::v-deep .menu-icon-line {
		    border-color: #000 !important;
	    }
    }

}
</style>
